<template>
    <v-dialog v-model="deleteDialog" width="500" :disabled="disabled">
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="w-100">
                <slot></slot>
            </div>
        </template>
        <v-card>
            <v-card-title>
                <v-icon left>delete</v-icon>
                Delete
            </v-card-title>
            <v-card-text class="pt-3">
                {{ warningText() }}
                <ul class="mt-3">
                    <li v-for="app in selected" :key="app.aid">{{ app.long_id }}</li>
                </ul>
            </v-card-text>
            <v-card-actions>
                <div v-if="fetchingDeids" class="caption">
                    <v-progress-circular indeterminate color="primary" size="16" />
                    <span class="ml-3">Fetching application data</span>
                </div>
                <v-checkbox v-else-if="envs > 0" color="error" v-model="deleteExportedApps">
                    <template v-slot:label>
                        <span class="caption">Also delete exported versions</span>
                    </template>
                </v-checkbox>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="deleteDialog = false" :disabled="loading">Cancel</v-btn>
                <v-btn color="error" text @click="deleteApps" :loading="loading" :disabled="fetchingDeids">Delete</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'AppDeleteDialog',
    data() {
        return {
            loading: false,
            deleteDialog: false,
            deleteExportedApps: true,
            fetchingDeids: false,
            envs: []
        }
    },
    props: {
        selected: {
            type: Array,
            default() {
                return []
            }
        },
        mode: { type: Number, default: null },
        disabled: { type: Boolean, default: false }
    },
    methods: {
        async deleteApps() {
            this.loading = true
            try {
                let url = `/instances/${this.$route.params.iid}/applications/delete`
                if (this.mode) {
                    url += `?mode=${this.mode}`
                }
                const request = { aids: this.selected.map(app => app.aid), delete_exported: this.deleteExportedApps }
                await this.$axios.post(url, request)
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Failed to delete applications',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }

            this.$store.dispatch('snapshotStore/fetchApplications', this.$route.params.snid)
            const userTasksRoute = this.$router.resolve({ name: 'user-tasks' }).href
            this.$store.dispatch('showSnackBar', {
                snackBarText: `Applications successfully submitted for deletion, you can track their progress <a href="${userTasksRoute}">here</a>`,
                snackBarTimeout: 5000,
                snackBarIcon: 'check_circle'
            })

            this.$emit('finished')
            this.loading = false
            this.deleteDialog = false
        },
        // We might not even need this, have to talk about the differences between app delete and APPS delete
        async fetchEnvironmentsForApps() {
            this.fetchingDeids = true
            try {
                const promises = this.selected.map(app => {
                    // snapshots/snid/list_exports > returns all snapshot exports > filter
                    return this.$axios.get(`applications/${app.aid}/list_exports`)
                })
                const { data } = await Promise.all(promises)
                this.envs = data
            } catch {
                this.envs = []
            }
            this.fetchingDeids = false
        },
        warningText() {
            if (this.mode === 2) {
                return `Are you sure you want to delete all distributed versions of selected applications from the other instances permanently? The applications would still be available for you.`
            }
            if (this.mode === 1) {
                return `Are you sure you want to delete your and all distributed versions of selected applications permanently?`
            }
            return `Are you sure you want to delete selected applications permanently?`
        }
    },
    watch: {
        deleteDialog(dialog) {
            if (dialog) {
                this.fetchEnvironmentsForApps()
            }
        }
    }
}
</script>
